/* List item animation for icons */
.linave {
  opacity: 0;
  list-style: none;
  transform-origin: bottom;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out; /* Ensure smooth transition */
}

.linave.animate-itop {
  animation: itop 300ms forwards;
}

.linave.animate-itop.reverse {
  animation: itopReverse 500ms forwards;
}

.linave.animate-itop.delay-300 {
  animation-delay: 300ms;
}

.linave.animate-itop.delay-400 {
  animation-delay: 400ms;
}

.linave.animate-itop.delay-500 {
  animation-delay: 500ms;
}

.linave.animate-itop.delay-600 {
  animation-delay: 600ms;
}

@keyframes itop {
  0% {
    opacity: 0;
    transform: translateY(60px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes itopReverse {
  0% {
    opacity: 1;
    transform: translateY(0); /* Start from the current position */
  }
  100% {
    opacity: 0;
    transform: translateY(60px); /* Move upwards */
  }
}
